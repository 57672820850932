<template>
	<div class="look-box pd16_15">
		<div class="flex alcenter space">
			<div class="flex alcenter">
				<img style="width: 20px; height: 20px;" class="mr10" v-if="datas.style.icon && datas.style.icon !='' " :src="datas.style.icon" />
				<img v-else style="width: 20px; height: 20px;" class="mr10" src="../../../../../assets/image/home_tui_technician@3x.png" />
				<span class="ft16 ftw600 cl-main">{{datas.style.title ? datas.style.title:'明星教练'}}</span>
			</div>
			<div>
				<span class="ft12 cl-main">查看全部</span>
				<span class="iconfont iconarrowright_black ft12 cl-main ml5"></span>
			</div>
		</div>
		
		
		<div class="mt16">
			<a-spin :spinning="loading">
				<div v-if="calldata == null" class="plugin-empty" style="height: 120px;line-height: 120px;">暂无数据</div> 
				<div v-else class="flex">
					<div v-for="(item,index) in calldata" :key="index" :class="{ml15:index > 0}" >
						<img :src="item.face" class="coach-face"/>
						<div class="ft14 ftw600 cl-black mt8 text-center text-over">{{item.name}}</div>
						<div class="ft12 ftw400 cl-notice mt4 text-center text-over">已约{{item.engaged_num}}人次</div>
					</div>
				</div>
			</a-spin>
		</div>
	</div>
</template>

<script>
	export default{
		props:{
			mainColor:{
				type:String,
				default:'',
			},
			datas:{
				type:Object,
				default:function(){
					return new Object;
				}
			}
		},
	
		data(){
			return {
				loading:false,
			}
		},
		computed:{
			calldata(){
				return this.datas.datas;
			}
		},
		created(){
			
		},
		methods:{
			
		}
	}
</script>

<style>
	.coach-face{
		width: 95px;
		height: 95px;
		border-radius: 4px;
	}
</style>